import { render, staticRenderFns } from "./errorRegistro.vue?vue&type=template&id=974e20ee&scoped=true"
import script from "./errorRegistro.vue?vue&type=script&lang=js"
export * from "./errorRegistro.vue?vue&type=script&lang=js"
import style0 from "./errorRegistro.vue?vue&type=style&index=0&id=974e20ee&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "974e20ee",
  null
  
)

export default component.exports